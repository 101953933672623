import React, {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import {ContactShadows, Float, PresentationControls, TorusKnot} from '@react-three/drei';
import {addClass, removeClass} from 'utils/HtmlClassUtils';
import {sizeClassStore} from 'stores';
import {canvasStore, FSMStore} from 'webgl/stores';
import {FSMStates} from 'webgl/types/FSMStates';
import {ACESFilmicToneMapping, MeshPhysicalMaterial} from 'three';
import {Canvas} from '@react-three/fiber';
import Configuration3D from 'webgl/components/Configuration/Configuration3D';
import BackgroundSphere from 'webgl/components/BackgroundSphere';
import RenderController from 'webgl/components/RenderController';
import Lighting from 'webgl/components/Lighting';
import Effects from 'webgl/components/Effects';
import CameraControls from 'webgl/components/CameraControls/CameraControls';
import CameraWobble from 'webgl/components/CameraControls/CameraWobble';
import CameraMainRotate from 'webgl/components/CameraControls/CameraMainRotate';

import './WebGLCanvas.scss';

type WebGLCanvasProps = {
  allowBlur?: boolean
};

const WebGLCanvas: React.FC<WebGLCanvasProps> = ({allowBlur}) => {
  const webglDivRef = useRef<HTMLDivElement>(null);
  const isHidden = canvasStore(state => state.isHidden);
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const [shouldRegress, setRegress] = useState<boolean>(false);

  //#region Blur Canvas DIV

  const handleBlur = useCallback(() => {
    if (currentFSMState === FSMStates.loading ||
      currentFSMState === FSMStates.loaded
      // || currentFSMState === FSMStates.screenSaver
    ) {
      setRegress(true);
      addClass(webglDivRef, 'blurred');
    } else {
      setRegress(false);
      removeClass(webglDivRef, 'blurred');
    }
  }, [currentFSMState]);

  // State Management
  useEffect(() => {
    if (allowBlur) handleBlur();
  }, [allowBlur, currentFSMState, handleBlur]);

  //#endregion

  return (
    <div
      ref={webglDivRef}
      className={`webgl-canvas ${sizeClass} ${isHidden ? 'hidden' : 'visible'}`}
    >
      <Canvas
        shadows
        dpr={[1, 1]}
        performance={{min: 0.1}}
        gl={{antialias: true, preserveDrawingBuffer: true/*, alpha: true*/}}
        onCreated={({gl}) => {
          gl.toneMapping = ACESFilmicToneMapping;
        }}
        camera={{position: [8.7, 6.5, 11.4], fov: 75}}
        frameloop={'demand'}
      >

        <CameraControls/>
        <CameraWobble/>
        <CameraMainRotate leftAngle={-0.2} rightAngle={0.75} damping={0.02} activeState={FSMStates.webgl.main}/>
        <Suspense fallback={null}>

          <RenderController/>
          <Lighting/>
          <BackgroundSphere/>

          <Configuration3D/>
          {/*<DebugCube/>*/}

          {/*<gridHelper/>*/}
          <Effects/>

        </Suspense>
      </Canvas>
    </div>
  );
};

export default WebGLCanvas;