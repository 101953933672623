import React, { useCallback, useEffect, useState } from 'react';
import { carrouselStore } from 'stores';
import { logHelper, tLogStyled } from 'utils/Logger';
import { FSMStore } from 'webgl/stores';
import { CarrouselItem } from 'stores/carrouselStore';
// import {getYoutubeId, isYoutubeUrl, YoutubeThumbnail, YoutubeVideo} from 'components/Main/SidePanel/YoutubeVideo';

import prevCircleIcon from 'assets/icons/arrow-left-circle.svg';
import nextCircleIcon from 'assets/icons/arrow-right-circle.svg';
import './Carrousel.scss';

type CarrouselProps = {
  items?: CarrouselItem[]
}

const Carrousel: React.FC<CarrouselProps> = ({ items }) => {
  const currentFSMState = FSMStore(state => state.currentFSMState); // to reset when changing state if index > item.length on the next state
  const { storeIndex, setCarrouselItems, setDisplayItemModal, displayModal } = carrouselStore(state => ({
    storeIndex: state.carrouselIndex,
    setCarrouselItems: state.setCarrouselItems,
    setDisplayItemModal: state.setDisplayCarrouselModal,
    displayModal: state.displayCarrouselModal
  }));

  const [currentIndex, setCurrentIndex] = useState<number>(0);
  // const [displayYoutubePlayer, setDisplayYoutubePlayer] = useState<boolean>(false);

  useEffect(() => {
    setCarrouselItems(items); // store

    setCurrentIndex(0);
    setDisplayItemModal(false);
  }, [items, setCarrouselItems, setDisplayItemModal, currentFSMState]);

  useEffect(() => {
    setCurrentIndex(storeIndex);
  }, [storeIndex]);

  // useEffect(() => {
  //   if (items) {
  //     const _isYoutubeUrl = isYoutubeUrl(items[currentIndex].url);
  //     setDisplayYoutubePlayer(_isYoutubeUrl);
  //   }
  // }, [currentIndex, items]);

  const setPreviousItem = useCallback(() => {
    if (items)
      setCurrentIndex(currentIndex === 0 ? items.length - 1 : currentIndex - 1);
  }, [currentIndex, items]);

  const setNextItem = useCallback(() => {
    if (items)
      setCurrentIndex(currentIndex === items.length - 1 ? 0 : currentIndex + 1);
  }, [currentIndex, items]);

  const openItemModal = useCallback(() => {
    if (items && items[currentIndex]) {
      setDisplayItemModal(true, currentIndex);
      tLogStyled(`[Carrousel.openItemModal] opening carrousel modal`, logHelper.event, currentIndex, items[currentIndex]);
    }
  }, [currentIndex, items, setDisplayItemModal]);

  if (!items || items.length < 1 || displayModal) return null;

  return (
    <div className={`carrousel`}>
      {items.length > 1 &&
        <div className="carrousel-arrow" onClick={setPreviousItem}>
          <img src={prevCircleIcon} alt={'previous'} />
        </div>}

      <div className={`carrousel-item`}>
        {/*{displayYoutubePlayer ?*/}
        {/*  <img src={`https://img.youtube.com/vi/${getYoutubeId(items[currentIndex].url)}/mqdefault.jpg`} alt={items[currentIndex].caption} onClick={openItemModal}/> :*/}
        {/*  <img src={items[currentIndex].url} alt={items[currentIndex].caption} onClick={openItemModal}/>*/}
        {/*}*/}

        <img src={items[currentIndex].url} alt={items[currentIndex].caption} onClick={openItemModal} />
        {items[currentIndex].caption && <div className="carrousel-item-caption-small" dangerouslySetInnerHTML={{ __html: items[currentIndex].caption || '' }} style={{ textAlign: 'center' }} />}

      </div>

      {items.length > 1 &&
        <div className="carrousel-arrow" onClick={setNextItem}>
          <img src={nextCircleIcon} alt={'next'} />
        </div>}
    </div>
  );
};

export default Carrousel;