import React, {useCallback} from 'react';
import {FSMStore} from 'webgl/stores';
import {FSMStates} from 'webgl/types/FSMStates';
import {useFullpagePanel, useGetNavigationData} from 'hooks';

import prevIcon from 'assets/icons/arrow-left.svg';
import mainIcon from 'assets/icons/main-icon.svg';
import nextIcon from 'assets/icons/arrow-right.svg';
import prevCircleIcon from 'assets/icons/arrow-left-circle.svg';
import nextCircleIcon from 'assets/icons/arrow-right-circle.svg';
import closeCircleIcon from 'assets/icons/close-circle.svg';
import './Navigation.scss';

const Navigation: React.FC = () => {
  const setFSMState = FSMStore(state => state.setFSMState);

  const [fullpage, displayBackground] = useFullpagePanel();
  const [displaySubstateArrows, displayNavigation, prevState, nextState, parentState] = useGetNavigationData();

  const gotoPrevState = useCallback(() => prevState && setFSMState(prevState), [prevState, setFSMState]);
  const gotoWebGLMainState = useCallback(() => setFSMState(FSMStates.webgl.main), [setFSMState]);
  const gotoParentState = useCallback(() => parentState && setFSMState(parentState), [parentState, setFSMState]);
  const gotoNextState = useCallback(() => nextState && setFSMState(nextState), [nextState, setFSMState]);

  if (displayNavigation) { // DISPLAY PREVIOUS HOME NEXT BUTTONS
    return (
      <div className={`navigation ${fullpage ? 'top-right' : 'bottom-right'} ${displayBackground ? 'background' : ''}`}>
        <div className={`navigation-button previous ${prevState ? '' : 'disabled'}`} onClick={gotoPrevState}>
          <img src={prevIcon} alt={'previous'}/>
          <div>PRECEDENT</div>
        </div>
        <div className="navigation-button-divider"/>
        <div className="navigation-button main" onClick={gotoWebGLMainState}>
          <img src={mainIcon} alt={'return to main'}/>
        </div>
        <div className="navigation-button-divider"/>
        <div className={`navigation-button next ${nextState ? '' : 'disabled'}`} onClick={gotoNextState}>
          <div>SUIVANT</div>
          <img src={nextIcon} alt={'next'}/>
        </div>
      </div>
    );


  } else if (displaySubstateArrows) { // DISPLAY ARROWS AND CLOSE
    return (
      <div className="navigation">
        <div className={`navigation-button-circle previous ${prevState ? '' : 'hidden'}`} onClick={gotoPrevState}>
          <img src={prevCircleIcon} alt={'previous'}/>
        </div>
        <div className="navigation-button-circle close" onClick={gotoParentState}>
          <img src={closeCircleIcon} alt={'close'}/>
        </div>
        <div className={`navigation-button-circle next ${nextState ? '' : 'hidden'}`} onClick={gotoNextState}>
          <img src={nextCircleIcon} alt={'next'}/>
        </div>
      </div>
    );
  } else return null;
};

export default Navigation;