export const FSMStates = {
  notLoaded: 'notLoaded',
  loading: 'loading',
  loaded: 'loaded',
  parsing: 'parsing',
  parsed: 'parsed',
  loadingError: 'loadingError',
  hidden: 'hidden',
  // homepage: 'homepage',


  webgl: {
    main: 'webgl.main',
    points: {
      pt1: 'webgl.points.pt1',
      pt1_sub: {
        graph1: 'webgl.points.pt1_sub.graph1',
        graph2: 'webgl.points.pt1_sub.graph2',
      },
      pt2: 'webgl.points.pt2',
      pt3: 'webgl.points.pt3',
      pt4: 'webgl.points.pt4',
      pt4_sub: {
        risque_bruit: 'webgl.points.pt4_sub.risque_bruit',
        risque_chimique: 'webgl.points.pt4_sub.risque_chimique',
        risque_chute: 'webgl.points.pt4_sub.risque_chute',
        risque_electrique: 'webgl.points.pt4_sub.risque_electrique',
        risque_mecanique: 'webgl.points.pt4_sub.risque_mecanique',
      },
      pt5: 'webgl.points.pt5',
      pt5_sub: {
        zoom: 'webgl.points.pt5_sub.zoom',
      },
      pt6: 'webgl.points.pt6',
      pt7: 'webgl.points.pt7'
    },
    // contact: 'webgl.contact',
    credits: 'webgl.credits'
  },


  // below not used, kept for backward compatibility
  line: {
    default: 'line.default'
    // filled dynamically when custom line config is received
  } as { [p: string]: any }
};
