import {FSMStore} from 'webgl/stores';
import {useEffect, useState} from 'react';
import {FSMStates} from 'webgl/types/FSMStates';

export const useFullpagePanel = (): [boolean, boolean] => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const [fullpage, setFullpage] = useState<boolean>(false);
  const [displayBackground, setDisplayBackground] = useState<boolean>(false);

  useEffect(() => {
    switch (currentFSMState) {
      case FSMStates.webgl.points.pt6:
      case FSMStates.webgl.points.pt7:
      // case FSMStates.webgl.contact:
      case FSMStates.webgl.credits: {
        setDisplayBackground(false);
        setFullpage(true);
        break;
      }
      case FSMStates.webgl.points.pt1: {
        setDisplayBackground(true);
        setFullpage(false);
        break;
      }
      default: {
        setDisplayBackground(false);
        setFullpage(false);
        break;
      }
    }
  }, [currentFSMState]);

  return [fullpage, displayBackground];
};