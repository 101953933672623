import React, {useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import {ForcedAngle} from 'webgl/hooks/annotations/useAnnotationForcedAngle';
import {Vector2} from 'three';
import {FSMStates} from 'webgl/types/FSMStates';
import Homepage from 'components/Main/Homepage';
import Navigation from 'components/Main/Navigation';
import SidePanel from 'components/Main/SidePanel/SidePanel';
import CarrouselModal from 'components/Main/SidePanel/CarrouselModal';
import WebGL from 'webgl/components/WebGL';
import AnnotationOrangeFixedDescriptions from 'webgl/components/Annotations/AnnotationOrangeFixedDescriptions';
import AnnotationDangerAlwaysOpened_FIXED from 'webgl/components/Annotations/AnnotationDangerAlwaysOpened_FIXED';

import './Main.scss';

const Main: React.FC = () => {
  return (
    <div className={`main-container flex flex-center`}>
      <Routes>
        {/*<Route path={'/'} element={<Homepage/>}/>*/}
        <Route path="*" element={<Homepage/>}/>
      </Routes>

      <WebGL/>
      <SidePanel/>
      <CarrouselModal/>
      <Navigation/>
      <AnnotationOrangeFixedDescriptions/>
      <AnnotationDangerAlwaysOpened_FIXED annotationId={'annotation_main_non-conformite'} position={new Vector2(100, 50)} forcedAngle={ForcedAngle.topRight} activeState={FSMStates.webgl.main}/>

    </div>
  );
};

export default Main;
