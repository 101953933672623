import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Vector2} from 'three';
import {sizeClassStore} from 'stores';
import {useAnnotationForcedAngle} from 'webgl/hooks';
import {getAnnotationDataFromId__JSON} from 'services/getAnnotationData';
import {ForcedAngle} from 'webgl/hooks/annotations/useAnnotationForcedAngle';
import {FSMStore} from 'webgl/stores';

import './AnnotationBase.scss';
import './AnnotationDangerAlwaysOpened.scss';

type AnnotationProps = {
  annotationId: string;
  position: Vector2;
  forcedAngle: ForcedAngle;
  activeState: string;
}

const AnnotationDangerAlwaysOpened_FIXED: React.FC<AnnotationProps> = ({
                                                                         annotationId,
                                                                         position,
                                                                         forcedAngle,
                                                                         activeState
                                                                       }: AnnotationProps) => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const sizeClass = sizeClassStore(state => state.sizeClass);

  const [markerChar, setMarkerChar] = useState<string | undefined>(); // TODO retrieve from backend
  const [markerTooltip, setTooltip] = useState<string | undefined>(); // TODO retrieve from backend

  const isPointerDown = useRef<boolean>(false);
  const [isHovered, setHovered] = useState<boolean>(false);

  const [, forcedPercentages] = useAnnotationForcedAngle(forcedAngle);
  // const [annotationRef] = useAnnotationPositioning_FIXED(...annotationAngle);

  useEffect(() => {
    // TODO setNumber & setTitle
    getAnnotationDataFromId__JSON(annotationId) // TODO QUICKFIX !
      .then(res => {
        setMarkerChar(res.marker);
        setTooltip(res.description);
      });
  }, [annotationId]);

  const onPointerEnter = useCallback(() => setHovered(true), []);
  const onPointerLeave = useCallback(() => setHovered(false), []);

  if (currentFSMState !== activeState) return null;

  return (

    <div className="annotation-container"
      style={{pointerEvents: 'none', position: 'absolute', left: position.x, bottom: position.y}}>

      <svg className={`annotation-circle dangerAlwaysOpened ${sizeClass} `}
        onPointerEnter={onPointerEnter}
        onPointerLeave={onPointerLeave}
        style={{cursor:'auto'}}
      >
        <circle id="backCircle"/>
        <circle id="frontCircle"/>
        <text x="50%" y="50%" dy=".3em">{markerChar}</text>
      </svg>

      {markerTooltip &&
      <div className={`annotation dangerAlwaysOpened ${sizeClass} visible`}
        style={{maxWidth: '29rem', transform: `translate(${forcedPercentages.x}%, ${forcedPercentages.y}%)`}}>
        <div className={`annotation-tooltip ${sizeClass}`} dangerouslySetInnerHTML={{__html: markerTooltip}}/>
        {/*TODO keep using dangerouslySetInnerHTML ? */}
      </div>}

    </div>
  );
};

export default AnnotationDangerAlwaysOpened_FIXED;
