import React, {useEffect} from 'react';
import {sizeClassStore} from 'stores';
import {fixedAnnotationStore} from 'webgl/stores';
import {getAnnotationsData__JSON} from 'services/getAnnotationData'; // TODO REMOVE

import './AnnotationBase.scss';
import './AnnotationOrangeFixed.scss';
import {OrangeFixedCircle} from 'webgl/components/Annotations/AnnotationOrangeFixed';

const AnnotationOrangeFixedDescriptions: React.FC = () => {
  const sizeClass = sizeClassStore(state => state.sizeClass);
  const fixedAnnotationObjects = fixedAnnotationStore(state => state.fixedAnnotationObjects);
  const setFixedAnnotationDescriptions = fixedAnnotationStore(state => state.setFixedAnnotationDescriptions);
  const fixedAnnotationDescriptions = fixedAnnotationStore(state => state.fixedAnnotationDescriptions);

  useEffect(() => {
    setFixedAnnotationDescriptions([]); // reset list when objects change before waiting for backend response

    getAnnotationsData__JSON(fixedAnnotationObjects) // TODO Retrieve Annotation descriptions from backend
      .then(res => setFixedAnnotationDescriptions(res));
  }, [fixedAnnotationObjects, setFixedAnnotationDescriptions]);

  if (!fixedAnnotationDescriptions || fixedAnnotationDescriptions.length < 1) return null;

  return (
    <div className={`fixedAnnotationsContainer ${sizeClass}`}>
      <div className={`annotation orangeFixed ${sizeClass}`}>
        <div className={`blurred-background title ${sizeClass}`}>La définition d'une machine répond aux critères ci-dessous :</div>
      </div>
      {fixedAnnotationDescriptions.map(({key, description, marker}) =>
        <div key={key} className={`annotation orangeFixed ${sizeClass}`}>
          <OrangeFixedCircle markerChar={marker} animated={false} opaque={true}/>
          <div className={`blurred-background description ${sizeClass}`}>{description}</div>
        </div>
      )}
    </div>
  );
};

export default AnnotationOrangeFixedDescriptions;
