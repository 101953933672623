import {Size, useFrame, useThree} from '@react-three/fiber';
import React, {useEffect, useRef, useState} from 'react';
import {MathUtils} from 'three';

const CameraWobble: React.FC = () => {
  const {camera, mouse, viewport} = useThree();
  const [baseViewportSize, setBaseViewportSize] = useState<Size>({
    width: viewport.width * viewport.factor,
    height: viewport.height * viewport.factor
  });
  const multiplier = {x: 25, y: 25};
  const offset = useRef<{x:number, y:number}>({x:0, y:0});

  // update when viewport resizes
  useEffect(() => {
    setBaseViewportSize({
      width: viewport.width * viewport.factor,
      height: viewport.height * viewport.factor
    });
  }, [viewport.factor, viewport.height, viewport.width]);

  useFrame(() => {
    offset.current = {
      x: MathUtils.lerp(offset.current.x, mouse.x * multiplier.x, 0.05),
      y: MathUtils.lerp(offset.current.y, mouse.y * multiplier.y, 0.05)
    }
    camera.setViewOffset(baseViewportSize.width, baseViewportSize.height, offset.current.x, -offset.current.y, baseViewportSize.width, baseViewportSize.height);
  });

  return null;
};

export default CameraWobble;
