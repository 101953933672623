import React, {useRef} from 'react';
import {configurationStore, GLTFAssetStore, loadingStore} from 'webgl/stores';
import {useCameraController, useGetConfigurationEntities} from 'webgl/hooks';
import Line from 'webgl/components/Configuration/Line';
import Annotations from 'webgl/components/Annotations/Annotations';
import {ConfigurationDTO} from 'interfaces';
import AnnotationDangerAlwaysOpened from 'webgl/components/Annotations/AnnotationDangerAlwaysOpened';

const Configuration3D: React.FC = () => {
  const {isLoading} = loadingStore(state => ({isLoading: state.isLoading}));
  const {isParsing} = GLTFAssetStore(state => ({isParsing: state.isParsing}));
  const {/*configuration,*/ isConfiguration3dReady} = configurationStore(state => ({
    // configuration: state.configuration,
    isConfiguration3dReady: state.isConfiguration3dReady
  }));

  const configuration = useRef<ConfigurationDTO>({
      key: 'carsat_socle_machines',
      name: 'carsat_socle_machines',
      equipmentKeys: ['carsat_socle_machines'],
    });

  const [equipmentEntities] = useGetConfigurationEntities(configuration.current);

  useCameraController();

  if (isLoading || isParsing) return null;
  if (!configuration || !isConfiguration3dReady) return null;
  if (!equipmentEntities/* || !crateAsset || !crateContentAsset*/) return null;

  return (
    <group name={'Configuration3D Group'}>
      {/* EQUIPMENT CHAIN */}
      <Line equipments={equipmentEntities}/>

      <Annotations/>
    </group>
  );
};

export default Configuration3D;