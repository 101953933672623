import React, {useCallback, useEffect, useState} from 'react';
import {carrouselStore} from 'stores';
// import {isYoutubeUrl, YoutubeVideo} from 'components/Main/SidePanel/YoutubeVideo';

import prevCircleIcon from 'assets/icons/arrow-left-circle.svg';
import nextCircleIcon from 'assets/icons/arrow-right-circle.svg';
import closeCircleIcon from 'assets/icons/close-circle.svg';

import './Carrousel.scss';

const CarrouselModal: React.FC = () => {
  const {items, index, displayModal, setDisplayModal} = carrouselStore(state => ({
    items: state.carrouselItems,
    index: state.carrouselIndex,
    displayModal: state.displayCarrouselModal,
    setDisplayModal: state.setDisplayCarrouselModal
  }));

  // const [displayYoutubePlayer, setDisplayYoutubePlayer] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(index);

  useEffect(() => {
    setCurrentIndex(index);
  }, [index, displayModal]);

  // useEffect(() => {
  //   if (items) {
  //     const _isYoutubeUrl = isYoutubeUrl(items[currentIndex].url);
  //     setDisplayYoutubePlayer(_isYoutubeUrl);
  //   }
  // }, [currentIndex, items]);

  const setPreviousItem = useCallback(() => {
    if (items)
      setCurrentIndex(currentIndex === 0 ? items.length - 1 : currentIndex - 1);
  }, [currentIndex, items]);

  const setNextItem = useCallback(() => {
    if (items)
      setCurrentIndex(currentIndex === items.length - 1 ? 0 : currentIndex + 1);
  }, [currentIndex, items]);

  const closeItemModal = useCallback(() => {
    setDisplayModal(false, currentIndex);
  }, [currentIndex, setDisplayModal]);

  if (!items || items.length < 1 || !displayModal) return null;

  return (
    <div className={`carrousel-modal`}>
      <div className="carrousel-modal-close" onClick={closeItemModal}>
        <img src={closeCircleIcon} alt={'close modal'}/>
      </div>
      {items.length > 1 &&
      <div className="carrousel-arrow" onClick={setPreviousItem}>
        <img src={prevCircleIcon} alt={'previous'}/>
      </div>}

      <div className={`carrousel-item modal opened`}>
        {/*{displayYoutubePlayer ?*/}
        {/*  <YoutubeVideo item={items[currentIndex]}/> :*/}
        {/*  <img src={items[currentIndex].url} alt={items[currentIndex].caption}/>*/}
        {/*}*/}

        <img src={items[currentIndex].url} alt={items[currentIndex].caption}/>
        {items[currentIndex].caption && <div className="carrousel-item-caption-small" dangerouslySetInnerHTML={{__html: items[currentIndex].caption || ''}} style={{textAlign:'center'}}/>}

      </div>

      {items.length > 1 &&
      <div className="carrousel-arrow" onClick={setNextItem}>
        <img src={nextCircleIcon} alt={'next'}/>
      </div>}
    </div>
  );
};

export default CarrouselModal;