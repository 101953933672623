import {useFrame, useThree} from '@react-three/fiber';
import React, {useRef} from 'react';
import {MathUtils, Quaternion, Spherical} from 'three';
import {Vector3} from 'three/src/Three';
import {cameraStore, controlsStore, FSMStore} from 'webgl/stores';

type CameraMainRotateProps = {
  leftAngle: number;
  rightAngle: number;
  damping: number;
  activeState: string;
}

const CameraMainRotate: React.FC<CameraMainRotateProps> = ({leftAngle, rightAngle, damping, activeState}) => {
  const currentFSMState = FSMStore(state => state.currentFSMState);
  const activeVirtualCamera = cameraStore(state => state.activeVirtualCamera);
  const camera = useThree(state => state.camera);

  const newPosition = useRef<Vector3>(new Vector3());
  const smoothedPosition = useRef<Vector3>(new Vector3());

  const spherical = new Spherical();

  const {getTarget} = controlsStore(state => ({getTarget: state.getTarget, update: state.update}));
  const isLerping = cameraStore(state => state.isLerping);

  useFrame(({mouse}) => {
    if (isLerping || currentFSMState !== activeState || !activeVirtualCamera) {
      smoothedPosition.current.copy(camera.position);

    } else {
      const cameraTarget = getTarget();

      const twoPI = 2 * Math.PI;
      const offset = new Vector3();
      const quat = new Quaternion().setFromUnitVectors(camera.up, new Vector3(0, 1, 0));
      const quatInverse = quat.clone().invert();

      offset.copy(activeVirtualCamera.position).sub(cameraTarget);

      offset.applyQuaternion(quat); // rotate offset to "y-axis-is-up" space
      spherical.setFromVector3(offset); // angle from z-axis around y-axis


      const rotationPercentage = (mouse.x + 1) / 2; // [-1 to 1] => [0 to 1]
      const angle = MathUtils.lerp(leftAngle, rightAngle, rotationPercentage);
      spherical.theta = angle;


      spherical.makeSafe();
      offset.setFromSpherical(spherical);
      offset.applyQuaternion(quatInverse); // rotate offset back to "camera-up-vector-is-up" space
      newPosition.current.copy(cameraTarget).add(offset);
      smoothedPosition.current.copy(smoothedPosition.current.lerp(newPosition.current, damping));

      // APPLY POSITION
      camera.position.copy(smoothedPosition.current);
      camera.lookAt(cameraTarget);
    }
  });

  return null;
};

export default CameraMainRotate;
